import React from 'react'
import { UserInfoProvider } from '@nke/spp-components.context.user-info'
import Sidebar from './sidebar'
import { theme } from '@NKE/utils'
import {
  createGenerateClassName,
  StylesProvider,
  ThemeProvider,
} from '@material-ui/core'
import './i18next'

const generateClassName = createGenerateClassName({
  disableGlobal: true,
  seed: 'themeSidebarComponent',
})

export default function Root(props) {
  return (
    <StylesProvider generateClassName={generateClassName}>
      <ThemeProvider theme={theme}>
        <UserInfoProvider token={localStorage.getItem('token') ?? ''}>
          <Sidebar />
        </UserInfoProvider>
      </ThemeProvider>
    </StylesProvider>
  )
}
