export default {
  en: {
    translation: {
      custAndRet: 'Customers & Retailer',
      prodAndDocs: 'Products & Documents',
      settings: 'Settings',
    },
  },
  it: {
    translation: {
      custAndRet: 'Clienti & Rivenditori',
      prodAndDocs: 'Prodotti & Documenti',
      settings: 'Impostazioni',
    },
  },
}
