import { makeStyles, Tooltip } from '@material-ui/core'
import { useUserInfo } from '@nke/spp-components.context.user-info'
import React, { FunctionComponent, useState } from 'react'
import AccountTreeIcon from '@material-ui/icons/AccountTree'
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount'
import ViewListIcon from '@material-ui/icons/ViewList'
import SettingsIcon from '@material-ui/icons/Settings'
import { useTranslation } from 'react-i18next'

const useStyle = makeStyles(theme => ({
  sidebar: {
    position: 'absolute',
    top: 64,
    width: '48px',
    height: 'calc(100vh - 64px)',
    background: theme.palette.primary.light,
    display: 'flex',
    flexDirection: 'column',
  },
  icon: {
    color: theme.palette.grey[200],
    padding: `${theme.spacing(1.5)}px`,
    cursor: 'pointer',
    display: 'flex',
  },
  selectedIcon: {
    color: theme.palette.primary.main,
    background: theme.palette.grey[200],
  },
  settingsIcon: {
    marginTop: 'auto',
  },
}))

interface ISidebarIcon {
  className: string
  tooltip: string
  clickAction: () => void
}

const SidebarIcon: FunctionComponent<ISidebarIcon> = ({
  tooltip,
  className,
  children,
  clickAction,
}) => (
  <Tooltip title={tooltip} placement='right' arrow>
    <div className={className} onClick={clickAction}>
      {children}
    </div>
  </Tooltip>
)

const Sidebar = () => {
  const [Pathname, setPathname] = useState(window.location.pathname)
  const userInfo = useUserInfo()
  const { t } = useTranslation()

  window.addEventListener('single-spa:before-routing-event', (ev: any) => {
    const _newPathname = ev.detail.newUrl.replace(window.location.origin, '')
    setPathname(_newPathname)
  })

  const _classes = useStyle()

  const _icons = [
    {
      iconComponent: <AccountTreeIcon />,
      tooltip: 'SPP',
      className: `${_classes.icon} ${
        Pathname.includes('spp') ? _classes.selectedIcon : ''
      }`,
      clickAction: () => history.pushState(null, '', '/spp'),
    },
    {
      iconComponent: <SupervisorAccountIcon />,
      tooltip: t('custAndRet'),
      className: `${_classes.icon} ${
        Pathname.includes('platform') ? _classes.selectedIcon : ''
      }`,
      clickAction: () => history.pushState(null, '', '/platform'),
    },
    {
      iconComponent: <ViewListIcon />,
      tooltip: t('prodAndDocs'),
      className: `${_classes.icon} ${
        Pathname.includes('models') ? _classes.selectedIcon : ''
      }`,
      clickAction: () => history.pushState(null, '', '/models'),
    },
  ]

  const _userType = userInfo.type ? parseInt(userInfo.type) : 3
  return _userType < 3 ? (
    <div className={_classes.sidebar}>
      {_icons.map((icon, i) => (
        <SidebarIcon key={i} {...icon}>
          {icon.iconComponent}
        </SidebarIcon>
      ))}
      <SidebarIcon
        tooltip={t('settings')}
        className={`${_classes.settingsIcon} ${_classes.icon} ${
          Pathname.includes('settings') ? _classes.selectedIcon : ''
        }`}
        clickAction={() => history.pushState(null, '', '/settings')}
      >
        <SettingsIcon />
      </SidebarIcon>
    </div>
  ) : (
    <div />
  )
}

export default Sidebar
